export const API_ROUTES = {
    AUTHENTICATION: 'https://github.com/login/oauth/authorize?client_id=7bba09ccd56a5f07ccff',
    GET_USER_INFORMATION: 'https://api.github.com/user', // we really don't need to proxy this
    GET_AUTHENTICATION_TOKEN: 'https://pgj1bt4l1h.execute-api.us-east-1.amazonaws.com/dev/v1/authenticate'
}

export const ERRORS = {
    BAD_VERIFICATION_CODE: 'bad_verification_code'
}

