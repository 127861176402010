import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { GitHub } from 'react-feather';
import { useAuth } from '../../context/AuthContext';
import { User, LogOut, Settings } from 'react-feather';
import Logo  from '../../P.png';
import { API_ROUTES } from '../../types';


const Navigation = () => {
    const { isAuthenticated, userInfo, logOut } = useAuth(); 

    return (
        <>
            <Navbar className="code-header" style={{justifyContent: 'space-between'}} >
                <Navbar.Brand href="#">
                    <img src={Logo} style={{height: '4vh', marginTop: '-8px', marginRight: '10px'}} alt="Logo" />
                    {' '}
                    Peruse Code - Examine Code Online
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="options" />
                <Navbar.Collapse style={{ flexBasis: 'auto', flexGrow: 0 }}>
                    {
                        isAuthenticated ? (
                            <NavDropdown style={{backgroundColor: '#000', borderRadius: '10px'}} id="user_account" title={userInfo ? `Hi, ${userInfo.login}` : 'John Doe'}>
                                <NavDropdown.Item href={userInfo ? userInfo.html_url : '#'} target="_blank" ><User size="18" />{' '}Profile</NavDropdown.Item>
                                <NavDropdown.Item><Settings size="18" />{' '} Prefrences</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => logOut()}><LogOut size="18" />{' '}Logout</NavDropdown.Item>
                            </NavDropdown>
                        ) : (
                            <Nav >
                                <Nav.Link className="btn btn-secondary outline" type="button" href={API_ROUTES.AUTHENTICATION}><GitHub size="18" />{' '}Login via Github</Nav.Link>
                            </Nav>
                        )
                    }
                    
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default Navigation;