import React, { useContext } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";

import { AppContext } from '../../context/AppProvider';

const CodeEditor = () => {
    const appContext = useContext(AppContext);
    
    return (
        <AceEditor
            className="code-editor"
            placeholder=""
            mode="python"
            theme="monokai"
            onLoad={() => console.warn("Impmlement on load")}
            onChange={() => console.warn("Implement on change")}
            fontSize={16}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={appContext.fileContent ? atob(appContext.fileContent.content): ''}
            setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
        }}/>
    )
}

export default CodeEditor;