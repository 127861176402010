import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Terminal } from 'react-feather';

const CodeRunButton = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    return (
        <>
            <Modal show={isOpen} onHide={() => setIsOpen(!isOpen)}>
                <Modal.Header closeButton>
                    <Modal.Title>Code Run</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sorry <code>Code Run Check</code> is currently unavailable</Modal.Body>
            </Modal>
            <Button 
                variant="dark" 
                className="edit-button"
                onClick={() => setIsOpen(true)}
            >
                <Terminal />Run Code</Button>   
        </>
    )
}

export default CodeRunButton;