import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';

type AppContextProps = {
    url: string, 
    data: any,
    friendlyUrl: string,
    setFriendlyUrl: (url: string | undefined) => void,
    handleSetUrl: (url: string | undefined) => any,
    getFile: (url: string) => any, 
    fileContent: any, 
    downloadUrl: string | null,
    setDownloadUrl: any,
    repo: any,
    clearError: () => any, 
    error: any
}

export const AppContext = createContext<Partial<AppContextProps>>({});

const HTTP_SUCCESS = 200;

const AppContextProvider = ({ children }: any) => {
    const [ url, setUrl ]: [ any, any ] = useState(localStorage.getItem('url'));
    const [ baseUrl, setBaseUrl ]: [any, any ] = useState(localStorage.getItem('baseUrl'));
    const [ friendlyUrl, setFriendlyUrl ]:[any, any] = useState(''); // Url without the API manipulation
    const [ data, setData ]: [ any, any ] = useState(null);
    const [ repo, setRepo ]: [ any, any ] = useState(null);
    const [ fileContent, setFileContent ] : [ any, any ] = useState(null);
    const [ downloadUrl, setDownloadUrl ] : [ any, any ] = useState(null);
    const [ error, setError ] : [ any, any ] = useState(null);
    const { token } = useAuth();

    const clearError = () => {
        setError(null);
    }

    // TODO: Make this mad comprehensive for different services
    const handleSetUrl = (url: string | undefined) => {
        if(!url) {
            return false;
        }
        if(!url.includes("github.com")) {
            setError("Enter a valid URL");
            return false
        }

        setFriendlyUrl(url); 
        const api_url = url.replace('github.com', 'api.github.com/repos');
        setBaseUrl(api_url);
        setUrl(`${api_url}/contents`);
        localStorage.setItem('url', `${api_url}/contents`);
        localStorage.setItem('baseUrl',  api_url);
    }

    const getRepoInformation = (url: string) => {
        axios.get(baseUrl, {
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((result: any) => {
            (result.status === HTTP_SUCCESS ) && setRepo(result.data);
        }).catch(() => {
            setError('Failed to get repo information');
            localStorage.removeItem('url')
        })
    }

    const getFile = (fileUrl: string) => {
        axios.get(fileUrl, {
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((result) => {
            (result.status === HTTP_SUCCESS) && setFileContent(result.data)
        }).catch(() => setError('Failed to get file.'))
    }

    useEffect(() => {
        const current_url = new URL(window.location.href);
        const q = friendlyUrl ? friendlyUrl: current_url.searchParams.get("q");
        
        if(q) {
            handleSetUrl(q);
        }
        
        if(!url) {
            return;
        }
        
        getRepoInformation(baseUrl);
        
        axios.get(url, {
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((result: any) => { 
            (result.status === HTTP_SUCCESS) ? setData(result.data) : setData([]); 
        }).catch(() => setError('Failed to get URL'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ url, baseUrl ]);

    return (
        <AppContext.Provider
            value={{
                url, 
                handleSetUrl,
                data,
                getFile,
                fileContent,
                setFriendlyUrl,
                downloadUrl,
                friendlyUrl,
                setDownloadUrl,
                repo, 
                clearError,
                error
            }}
        >
            {children}
        </AppContext.Provider>
    )

}

export default AppContextProvider;