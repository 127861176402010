import React, { useContext } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Code } from 'react-feather';
import  Directory from './Directory';
import { AppContext } from '../../context/AppProvider';

const DIRECTORY_TYPE = 'dir';
const FILE_TYPE = 'file'

const RenderFileList = (data: any, projectName: string, sub = false) => {
    const appContext = useContext(AppContext);
    if(!data) {
        return null;
    }
    
    return (
        <>
            <ListGroup className={sub ? 'subfile': ''}>
                { projectName && <h5 className="project-name"><strong>{projectName}</strong></h5> }
                {
                    data.map((item: any) => {
                        if(item.type === FILE_TYPE) {
                            // Component for code
                            return <ListGroup.Item  
                                style={{
                                    cursor: 'pointer'
                                }}
                                key={item.sha}
                                as="a"
                                onClick={() => { 
                                    if(appContext.getFile) {
                                        appContext.getFile(item._links.self);
                                        appContext.setDownloadUrl(item.download_url);
                                    }
                                }} ><Code style={{
                                    width: '14px',
                                    height: '14px',
                                    cursor: 'pointer'
                                }}/>  {item.name}</ListGroup.Item>
                        }

                        if(item.type === DIRECTORY_TYPE) {
                            // Component for folder
                            return (
                                <Directory
                                    key={item.sha}
                                    name={item.name}
                                    data={item}
                                    renderFileList={RenderFileList}
                                />
                            )
                        }

                        return null;
                    })
                }
            </ListGroup>
        </>
    )
}

const FileList = () => {
    const appContext = useContext(AppContext);
    return (
        <>
            {
                RenderFileList(appContext.data, appContext.repo ? appContext.repo.name : '')
            }
        </>
    )
}

export default FileList;