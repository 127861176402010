import React, { useState } from 'react';
import axios from 'axios';
import ListGroup from 'react-bootstrap/ListGroup';
import { FolderPlus, FolderMinus } from 'react-feather';

interface IDirectory {
    data: any,
    name: string, 
    renderFileList: (data: any, projectName: string, sub: boolean) => any
}

const Folder = (isExpaned = false) => (isExpaned ? <FolderMinus /> : <FolderPlus /> )

const Directory = ({ name, data , renderFileList }: IDirectory) => {
    const [ children,  setChildren ] = useState([]);
    const [ isExpaned, setIsExpaned ] = useState(false);
    
    const getChildren = () => {
        setIsExpaned(!isExpaned);
        axios.get(data._links.self).then((result) => setChildren(result.data))
    }
    
    return (
        <>
            <ListGroup.Item style={{cursor: 'pointer'}} onClick={() => getChildren() }>{Folder(isExpaned)} {name}</ListGroup.Item>
            {(isExpaned && children.length > 0 ) && renderFileList(children, '', true)}
        </>
    )
}


export default Directory;