import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card'
import { Play, DownloadCloud } from 'react-feather';
import { CodeEditor } from '../../components/editor';
import { FileList } from '../../components/editor';
import { CodeQualityButton, CodeRunButton } from './options';

import './style.css';
import { AppContext } from '../../context/AppProvider';

const ENTER_KEY_CODE = 13;

const CodeScreen = () => {
    const { handleSetUrl, downloadUrl, friendlyUrl, setFriendlyUrl } = useContext(AppContext);
    
    return(
        <Container fluid={true}>
            <Row>
                <Col className="panel file scroll-bar" xs="2"> 
                    <FileList />
                    <Card>
                        
                        <Card.Body>
                            <Card.Title>Your Ad Here</Card.Title>
                            <Card.Text>
                                <code>Your super cool ad could appear here.</code>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="panel code" xs="10" > 
                    <Col className="control-panel">
                        <Form inline> 
                            <Form.Control 
                                value={friendlyUrl}
                                onChange={(e: any) => setFriendlyUrl && setFriendlyUrl(e.target.value) }
                                onKeyDown={(e: any) => {
                                    if(e.keyCode === ENTER_KEY_CODE) {
                                        e.preventDefault();
                                        setFriendlyUrl && setFriendlyUrl(friendlyUrl);
                                        handleSetUrl && handleSetUrl(friendlyUrl);
                                    }   
                                }}
                                type="url" 
                                placeholder="Paste Github URL" />
                            <Button 
                                onClick={() => handleSetUrl && handleSetUrl(friendlyUrl)}
                                variant="dark" 
                                className="edit-button"><Play /> Pull Code</Button> 
                            <Button variant="dark" className="edit-button" onClick={
                                () => { 
                                    if(downloadUrl) {
                                        window.open(downloadUrl);    
                                    }
                                }
                            }><DownloadCloud /> Download</Button> 
                            <CodeQualityButton />
                            <CodeRunButton />
                        </Form>
                    </Col>
                    <CodeEditor />
                </Col>
            </Row>
        </Container>
    )
};

export default CodeScreen;
